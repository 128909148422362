import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ax from './axios.js'
import VueSimpleAlert from "vue-simple-alert";
import BlockUI from 'vue-blockui'
import VeeValidate from 'vee-validate';
import VueSweetalert2 from 'vue-sweetalert2';
import store from './store/store'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';
import * as VueGoogleMaps from 'vue2-google-maps'


Vue.use(VueSweetalert2);
Vue.component('v-select', vSelect)
Vue.use(BlockUI)
Vue.use(VeeValidate);
Vue.use(VueSimpleAlert);
Vue.prototype.axios = ax
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBrojaZi7341FnUdtdIYCOoRuKydR9yUa4',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },

})
Vue.mixin({
  data() {
    return {
      appenv:{
        key:'ABC',
        secret:'123',
        website:'#',
        hostedurl:'https://dev.eazisystems.com',
        baseurl:'https://dev.garnetdata.net/activity_tracker/dev/public'
      }
    }
  } 
});
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

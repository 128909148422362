import Vue from 'vue'
import VueRouter from 'vue-router'
import Error404 from '../views/Error404.vue'
import Login from '../views/admin/Login.vue'
import Home from '../views/admin/Home.vue'
import Gallery from '../views/admin/Gallery.vue'

import Activities from '../views/admin/Activities.vue'
import Users from '../views/admin/Users.vue'


Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'Login',
    component:Login
  },
  {
    path:'/admin/login',
    name:'AdminLogin',
    component:Login
  },
  {
    path:'/admin/home',
    name:'Home',
    component:Home
  },
  {
    path:'/admin/gallery',
    name:'Gallery',
    component:Gallery
  },
  {
    path:'/admin/activities',
    name:'Activities',
    component:Activities
  },

  {
    path:'/admin/users',
    name:'Users',
    component:Users
  },
  {
    path:'*',
    name:'404',
    component:Error404
  }
 
]

const router = new VueRouter({
  mode: 'history',
  base:process.env.BASE_URL,
  routes
})

export default router

<template>
   <!-- Sidebar -->
<ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar" style="background: green">

    <!-- Sidebar - Brand -->
    <a class="sidebar-brand d-flex align-items-center justify-content-center" href="#">
       
        <div class="sidebar-brand-text mx-3">ACTIVITY TRACKER</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0">

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
        <router-link class="nav-link" to="/admin/home">
            <i class="fas fa-fw fa-home"></i>
            <span>Dashboard</span></router-link>
    </li>
    <!-- <li class="nav-item active">
        <router-link class="nav-link" to="/admin/gallery">
            <i class="fas fa-fw fa-users"></i>
            <span>Gallery</span></router-link>
    </li> -->
    
    <li class="nav-item active">
        <router-link class="nav-link" to="/admin/activities">
            <i class="fas fa-fw fa-table"></i>
            <span>Activities</span></router-link>
    </li>
    <li class="nav-item active">
        <router-link class="nav-link" to="/admin/users">
            <i class="fas fa-fw fa-users"></i>
            <span>Users</span></router-link>
    </li>
    <li class="nav-item active">
        <a class="nav-link" @click.prevent="logout">
            <i class="fas fa-fw fa-list"></i>
            <span>Logout</span></a>
    </li>

</ul>
</template>

<script>
    export default {
        name:'AppSidebar',
        methods: {
            logout(){
                  localStorage.removeItem('userInfo')
                  window.location.href=this.appenv.hostedurl + "/admin/login"
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
<template>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-9" style="margin-top:50px">
            <div class="card o-hidden border-0 shadow-lg my-5">
                <div class="card-body p-0">
                    <div class="row">
                        <div class="col-lg-6 d-none d-lg-block bg-login-image" style="background:green">
                            <img src="/assets/img/party_logo.png" alt="" class="myimg">
                        </div>
                        <div class="col-lg-6">
                            <div class="p-5">
                                <div class="text-center">
                                    <h1 class="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                </div>
                                <form class="user">
                                    <div class="form-group">
                                        <input type="email" class="form-control form-control-user"
                                            id="exampleInputEmail" aria-describedby="emailHelp"
                                            placeholder="Enter Email Address..." v-model="formdata.email">
                                    </div>
                                    <div class="form-group">
                                        <input type="password" class="form-control form-control-user"
                                            id="exampleInputPassword" placeholder="Password" v-model="formdata.password">
                                    </div>
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox small">
                                            <input type="checkbox" class="custom-control-input" id="customCheck">
                                            <label class="custom-control-label" for="customCheck">Remember
                                                Me</label>
                                        </div>
                                    </div>
                                    <button @click="authenticate" type="button" class="btn btn-user btn-block" style="background:green;color:white">
                                        Login
                                    </button>
                                
                                </form>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <BlockUI :message="msg" v-if="blockui"></BlockUI>
</div>
</template>

<script>
import store from "@/store/store.js"
import '../../assets/css/sb-admin-2.min.css'
    export default {
        name:'Login',
        data(){
            return {
                blockui:false,
                msg:'Authenticating...',
                formdata:{
                    email:'',
                    password:''
                }
            }
        },
        methods:{
            authenticate(){
                if(this.formdata.email===''){
                     this.$alert("Please enter email to continue",'Alert','info');
                     return
                }
                if(this.formdata.password===''){
                     this.$alert("Please enter password to continue",'Alert','info');
                     return
                }
                this.blockui = true
                this.axios.post(this.appenv.baseurl+'/login',this.formdata).then((response) => {
                    console.log(typeof response.data.token)
                    this.blockui = false
                    if(typeof response.data.token !== 'undefined'){
                         store.commit("UPDATE_USER_INFO", {
                            token : response.data.token,
                             displayName: response.data.user.name,
                             userId: response.data.user.id,
                             email:response.data.user.email,
                        })
                         window.location.href=this.appenv.hostedurl + "/admin/home"
                    }else{
                        this.$alert("Invalid Username or Password",'Alert','error');
                    }
                    }).catch((e)=>{
                        console.log(e)
                       this.blockui = false
                        this.$alert("Invalid Username or Password",'Alert','error');
                    })

                

            }
        }
    }
</script>

<style lang="scss" scoped>
.myimg{
    position: relative;
    top: 5%;
    left: 8%;
    max-width:350px;
}
.bg-login-image {
    background-position: center;
    background-size: cover;
}
</style>
<template>
    <div>
           <section id="all-content">
       
        <div class="thnk_u_msg text-center">
            <div class="thnk_u_title"><img class="img-fluid logo" src="/assets/img/party_logo.png">
                <h1 id="thank_you">404 <br>PAGE NOT FOUND!</h1>
                <div style="padding-right: 17px;padding-left: 17px;">
                    <div class="btn-group" role="group">
                    <a class="btn receipt-dwnld-btn" role="button" @click.prevent="goBack()"><i class="fa fa-download"></i>Go Back</a>
                </div>
            </div>
        </div>
        </div>
    </section>
    </div>
</template>

<script>
    export default {
       methods:{
         goBack(){
           window.history.go(-1)
         }
       }

    }
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  body {
    overflow: hidden;
    font-family: 'Montserrat';
    padding: 0;
    /*margin-top: -50px;*/
  }
}

body {
  font-family: 'Montserrat';
}

#all-content {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0,0,0,0.78) 0%, rgba(0,0,0,0.43) 100%), url("/assets/img/pexels-akil-mazumder-1072824.jpg") no-repeat;
  background-size: auto, cover;
  position: relative;
  top: 0;
  bottom: 0;
}

.home-icon {
  padding: 50px;
  color: #fff;
}

.home-icon a {
  color: #fff;
  text-decoration: none;
}

.home-icon i {
  margin-right: 20px;
  font-size: 21px;
}

.home-icon a:hover {
  color: rgb(35,221,188);
}

/* thank you css here */

.thnk_u_title {
  color: #fff;
  margin-top: 2%;
}

@media (min-width: 768px) {
  #thank_you {
    margin-bottom: 40px;
    font-family: 'Raleway';
    font-size: 68px;
  }
}

@media (min-width: 576px) {
  #thank_you {
    margin-bottom: 40px;
    font-family: 'Raleway';
  }
}

@media (min-width: 300px) {
  #thank_you {
    margin-bottom: 40px;
    font-family: 'Raleway';
  }
}

hr {
  border: 2px solid #23ddbc;
  /*width: 100px;*/
  opacity: 1;
  justify-items: center;
  margin: 25px 42%;
}

@media (min-width: 768px) {
  .thnk_u_title p {
    margin-top: 48px;
    margin: 40px 30%;
  }
}

@media (max-width: 576px) {
  .thnk_u_title p {
    margin: 40px 11%;
  }
}

.logo {
  width: 200px;
}

.receipt-dwnld-btn {
  color: #fff;
  background: #019667;
  margin-right: 10px;
  border-radius: 0;
}

.receipt-dwnld-btn:hover {
  color: #ffff;
  background: #014832;
}

.receipt-dwnld-btn i {
  margin-right: 15px;
}

.another-dntn-btn {
  color: #fff;
  background: #019667;
  border-radius: 0;
}

@media (max-width: 576px) {
  .another-dntn-btn {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .receipt-dwnld-btn {
    font-size: 12px;
  }
}

.another-dntn-btn i {
  margin-right: 15px;
}

.another-dntn-btn:hover {
  color: #ffff;
  background: #014832;
}


</style>
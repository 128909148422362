<template>
  <Content>
    <div class="row mb-3">
      <div class="col">
        <div class="card">
          <div class="card-header">
          <h6 class="m-0 font-weight-bold text-primary">Filter Box</h6>
          </div>
          <div class="card-body">
            <div class="timeline-item">
              <div class="timeline-body">
                <form>
                  <div class="row">
                    <div class="col-2 form-group has-feedback">
                      <label>User</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="filter.user"
                      />
                      <span
                        class="glyphicon glyphicon-envelope form-control-feedback"
                      ></span>
                    </div>

                    <div class="col-2 form-group has-feedback">
                      <label>Title</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        v-model="filter.title"
                      />
                      <span
                        class="glyphicon glyphicon-envelope form-control-feedback"
                      ></span>
                    </div>
                   
                      <div class="col-2 form-group has-feedback">
                        <label>Date</label>
                      <date-picker
                        valueType="format"
                        name="dob"
                        class="form-control"
                        input-class="removeborder"
                         v-model="filter.date_created"
                        data-vv-as="Date of Birth"
                      ></date-picker>
                      <span
                        class="glyphicon glyphicon-envelope form-control-feedback"
                      ></span>
                    </div>
                      
                  </div>
                </form>
              </div>
              <div class="timeline-footer">
                <a class="btn btn-success btn-flat btn-xs" @click.prevent="filterData()">
                  <i class="fa fa-filter"></i> Filter</a>&nbsp;&nbsp;
                  <a class="btn btn-danger btn-flat btn-xs" @click.prevent="resetForm()">
                  <i class="fa fa-close"></i> Reset</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Activities</h6>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Location</th>
                    <th>Images</th>
                    <th>Videos</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i, index) in activities" :key="index">
                    <td>{{ i.id }}</td>
                    <td>{{ i.title }}</td>
                    <td>{{ i.description }}</td>
                    <td>{{ i.status ? 'Locked' : 'Open' }}</td>
                    <td>{{ i.location }}</td>
                    <td>
                      <ul>
                        <li v-for="m in i.images" :key="m.id">
                          <a href="#" @click.prevent="previewMedia(appenv.baseurl + '/' + m.path, m.name, true)">{{ m.name }}</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li v-for="j in i.videos" :key="j.id">
                          <a href="#" @click.prevent="previewMedia(appenv.baseurl + '/' + j.path, j.name, false)">{{ j.name }}</a>
                        </li>
                      </ul>
                    </td>
                    <td>
                      <a href="#" class="btn btn-warning" @click.prevent="moreActivity(i)">More</a>
                      <a href="#" :class="i.status ? 'btn btn-success' : 'btn btn-success'" @click.prevent="toggleLockStatus(i)">
                                  {{ i.status ? 'Unlock' : 'Lock' }}
                      </a>
                      <a href="#" class="btn btn-danger" @click.prevent=" deleteActivity(i.id,index)">Delete</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Paginate :pagination="pgnate" @paginate="fetchActivities()" :offset="4" />
            <div class="box">
              <div class="col-md-12">
                <!-- /.widget-user -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActivityModal :details="details" v-if="details !== null" />
    <BlockUI :message="msg" v-if="blockui"></BlockUI>



    <!-- Embedded PreviewModal -->
    <div class="modal fade" id="previewModal" tabindex="-1" aria-labelledby="previewModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="previewModalLabel">{{ previewTitle }}</h5>
       
            <button type="button" id="closeModal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
             
          </div>
          <div class="modal-body">
            <div v-if="isImage">
              <img :src="previewContent" class="img-fluid" alt="Preview Image">
            </div>
            <div v-else>
              <video controls class="w-100">
                <source :src="previewContent" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div class="modal-footer">
           
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

  </Content>
</template>

<script>
import { Modal } from 'bootstrap';
import Paginate from './components/Paginate.vue';
import ActivityModal from './modals/ActivityModal.vue';
import Content from './components/Content.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import '../../assets/vendor/fontawesome-free/css/all.css';

export default {
  components: {
    Content,
    Paginate,
    ActivityModal,
    DatePicker,
  },
  data() {
    return {
      details: null,
      msg: 'Please wait',
      blockui: false,
      filter: {
        user: '',
        title: '',
        date_created: ''
      },
      activities: [],
      activityDetails: {},
      ref: '',
      pgnate: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 1
      },
      offset: 4,
      previewTitle: '',
      previewContent: '',
      isImage: true
    };
  },
  methods: {
    resetForm() {
      Object.keys(this.filter).forEach(e => {
        this.filter[e] = '';
      });
    },

    filterData() {
      this.blockui = true;
      this.axios
        .post(this.appenv.baseurl + '/filter-activities' + '?page=' + this.pgnate.current_page, this.filter)
        .then(response => {
          this.blockui = false;
          if (response.data) {
            this.activities = response.data.data.data;
            if (this.activities.length > 0) {
              this.details = this.activities[0];
            }
            this.pgnate = response.data.data;
          }
        })
        .catch(() => {
          this.blockui = false;
        });
    },
    fetchActivities() {
      this.axios
        .get(this.appenv.baseurl + '/get-all-activities' + '?page=' + this.pgnate.current_page)
        .then(response => {
          if (response.data) {
            this.activities = response.data.data.data;
            if (this.activities.length > 0) {
              this.details = this.activities[0];
            }
            this.pgnate = response.data.data;
          }
        })
        .catch(() => {
          this.$alert('Ooops.. Loading Data failed. Please try again', 'Alert', 'error');
        });
    },
    moreActivity(moreItem) {
      this.details = moreItem;
      console.log(this.details.location.split(','))
      new Modal(document.getElementById('conference_modal'), {}).show();
    },

    
    previewMedia(content, title, isImage) {
      this.previewContent = content;
      this.previewTitle = title;
      this.isImage = isImage;
      new Modal(document.getElementById('previewModal'), {}).show();
    },
 
   
  deleteActivity(activityId,index) { 
    if (confirm("Are you sure you want to delete this activity?")) {
      this.axios
        .post(this.appenv.baseurl +'/delete-activity/' + `${activityId}`)
        .then(response => {
             
          if (response.data.status) {
            this.activities.splice(index,1)
            this.$alert('Activity deleted successfully', 'Success', 'success');
          
          } else {
            this.$alert('Failed to delete activity', 'Error', 'error');
          }
        })
        .catch(() => {
          this.$alert('Ooops.. Deleting activity failed. Please try again', 'Alert', 'error', );
        });
      }
  },


toggleLockStatus(activity) {
 
  const newStatus = activity.status ? 0 : 1;

  // Optimistically update the local status
  const previousStatus = activity.status;
  activity.status = newStatus;

  // Make an API request to update the status
  this.axios
    .post(`${this.appenv.baseurl}/update-activity-status/${activity.id}`, { status: newStatus })
    .then(response => {
      console.log(response);

      if (response.data.status) {
        // If the update was not successful, revert to the previous status
        activity.status = previousStatus;
      }
    })
    .catch(() => {
      // Revert the status in case of an error
      activity.status = previousStatus;
    })

}

},
  mounted() {
    this.fetchActivities();
  },
 
};
</script>

<style lang="scss" scoped>
/* Add any additional styles if needed */
</style>

<style scoped>
/* Styles for the embedded PreviewModal */
.modal-content {
  border-radius: 0.5rem;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-title {
  font-size: 1.25rem;
}
.modal-body img,
.modal-body video {
  max-width: 100%;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
}
</style>

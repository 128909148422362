<template>
  <Content>
    <div class="row">
      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-primary text-uppercase mb-1"
                >
                  Activities
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">{{report.activities}}</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings (Monthly) Card Example -->
      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  Videos
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">{{report.videos}}</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Earnings (Monthly) Card Example -->
      <!-- <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-left-info shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-success text-uppercase mb-1"
                >
                  Membership
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">0</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-users fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Pending Requests Card Example -->
      <div class="col-xl-4 col-md-6 mb-4">
        <div class="card border-left-warning shadow h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters align-items-center">
              <div class="col mr-2">
                <div
                  class="text-xs font-weight-bold text-warning text-uppercase mb-1"
                >
                  Images
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">{{report.images}}</div>
              </div>
              <div class="col-auto">
                <i class="fas fa-users fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    

    <div class="row">
      <div class="col-md-12">
        <div class="card shadow mb-4">
          <div class="card-header py-3 d-flex">
            <nav class="navbar navbar-light bg-light">
            <div class="container-fluid justify-content-start">
              <span class="font-weight-bold text-primary me-2" type="button">Today Activitites</span>
              <div>
                  <!-- <select class="form-control select2" v-model="filterby" @change="filterConference">
                    <option value="">Filter By</option>
                  <option  value="virtual">Virtual</option>
                  <option  value="inperson">inperson</option>
                </select> -->
              </div>
            </div>
          </nav>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-bordered"
                id="dataTable"
                width="100%"
                cellspacing="0"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Location</th>
                    <th>Images</th>
                    <th>Videos</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(i, index) in activities" :key="index">
                    <td>{{ i.id }}</td>
                     <td>
                      {{ i.title }}
                    </td>
                    <td>{{i.description}}</td>
                    <td>{{i.status ? 'Locked' :'Open'}}</td>
                    <td>{{i.location}}</td>
                    <td>
                      <ul>
                        <li v-for="m in i.images" :key="m.id"><a :href="appenv.baseurl+'/'+m.path" target="_blank">{{ m.name }}</a></li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li v-for="j in i.videos" :key="j.id"><a :href="appenv.baseurl+'/'+j.path" target="_blank">{{ j.name }}</a></li>
                      </ul>
                    </td>
                    <td>
                      <a
                        href="#"
                        class="btn btn-warning"
                        @click.prevent="moreActivity(i)"
                        >More</a>
                        <a href="#" :class="i.status ? 'btn btn-success' : 'btn btn-success'" @click.prevent="toggleLockStatus(i)">
                                  {{ i.status ? 'Unlock' : 'Lock' }}
                      </a>
                        <a
                        href="#"
                        class="btn btn-danger"
                        >Delete</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- modal -->
      <div class="modal fade" id="modal-primary">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">More Details</h5>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
             
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end modal -->
    </div>
    <ActivityModal :details="details" v-if="details !== null"/>
  </Content>
</template>

<script>
import {Modal} from 'bootstrap'
import Content from "./components/Content.vue";
import "../../assets/vendor/fontawesome-free/css/all.css";
import ActivityModal from './modals/ActivityModal.vue';
export default {
  components: {
    Content,
    ActivityModal
  },
  data() {
    return {
      filterby:'',
      mfilterby:'',
      report:{activities:0,images:0,videos:0},
      activities:[],
      details: null,
      inperson:"",
      virtual:""
    };
    
  },
  methods: {
    fetchReport(){
      
       this.axios
        .get(this.appenv.baseurl + "/reports")
        .then((response) => {
          if (response.data) {
            this.report.activities = response.data.activities;
            this.report.images = response.data.images;
            this.report.videos = response.data.videos;
          }
        })
        .catch(() => {
          this.$alert(
            "Ooops.. Loading Data failed. Please try again",
            "Alert",
            "error"
          );
        });
    },
    fetchTodayActivities() {
      this.axios
        .get(this.appenv.baseurl + "/get-today-activities")
        .then((response) => {
          if (response.data) {
            this.activities = response.data.data.data;
          }
          if(this.activities.length > 0){
                      this.details = this.activities[0];
                    }
        })
        .catch(() => {
          this.$alert(
            "Ooops.. Loading Data failed. Please try again",
            "Alert",
            "error"
          );
        });
    },
    toggleLockStatus(activity) {
 
 const newStatus = activity.status ? 0 : 1;

 // Optimistically update the local status
 const previousStatus = activity.status;
 activity.status = newStatus;

 // Make an API request to update the status
 this.axios
   .post(`${this.appenv.baseurl}/update-activity-status/${activity.id}`, { status: newStatus })
   .then(response => {
     console.log(response);

     if (response.data.status) {
       // If the update was not successful, revert to the previous status
       activity.status = previousStatus;
     }
   })
   .catch(() => {
     // Revert the status in case of an error
     activity.status = previousStatus;
   })

},
    moreActivity(moreItem) {
             this.details = moreItem
              new Modal(document.getElementById("conference_modal"), {}).show();
    },
     
  },
  mounted() {
    this.fetchTodayActivities()
    this.fetchReport()
  },
};
</script>

<style lang="scss" scoped></style>

<template>
    <div class="modal fade" id="conference_modal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="Conferencedetails">More Details</h5>
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table id="conferencedetails" class="table table-bordered table-hover">
              <tbody>
                <tr>
                  <th>ID</th>
                  <td>{{ details.id }}</td>
                </tr>
                <tr>
                  <th>Title</th>
                  <td>{{ details.title }}</td>
                </tr>
                <tr>
                  <th>Description</th>
                  <td>{{ details.description }}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>{{ details.status ? 'Locked':'Open' }}</td>
                </tr>
                 <tr>
                  <th>User</th>
                  <td>{{ details.user.name }}</td>
                </tr>
                 <tr>
                  <th>Images</th>
                  <td><ul>
                        <li v-for="m in details.images" :key="m.id"><a :href="appenv.baseurl+'/'+m.path">{{ m.name }}</a></li>
                      </ul></td>
                </tr>
                 <tr>
                  <th>Videos</th>
                  <td>
                    <ul>
                        <li v-for="m in details.videos" :key="m.id"><a :href="appenv.baseurl+'/'+m.path">{{ m.name }}</a></li>
                      </ul>  
                  </td>
                </tr>
                <tr v-if="details.location">
                  <th>Location</th>
                  <td>
                    <GmapMap
                    :center="{lat:Number(details.location.split(',')[0].trim()), lng:Number(details.location.split(',')[1].trim())}"
                    :zoom="7"
                    map-type-id="terrain"
                    style="width: 500px; height: 300px"
                  >
                  <!-- <GmapMarker ref="myMarker"
                  :position="google && new google.maps.LatLng(details.location.split(',')[0], details.location.split(',')[1])" />-->
                  </GmapMap> 
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->  
    </div>
</template>

<script>
    export default {
       props:['details'] 
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <Content>
        <div class="row">
              
        </div>
        <Members :details="details" v-if="details !== null"/>
        <BlockUI :message="msg" v-if="blockui"></BlockUI>
    </Content>
</template>

<script>

import Content from './components/Content.vue';
import "vue2-datepicker/index.css";
import '../../assets/vendor/fontawesome-free/css/all.css'
    export default {
        components: {
            Content,
        
        },
        data(){
            return {
            
            }
        },
        methods:{
         
        },
        mounted(){
          
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <Content>
                <div class="row">
                <div class="col-xs-12">
                <div class="box box-default">
                    <div class="box-header with-border">
                    </div>
                    <div class="box-body">
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-default">
                        Add user
                    </button>
                    </div>
                </div>
                </div>
            </div> <br>
            <div class="modal fade" id="modal-default">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h6 class="text-primary">Add User</h6>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span></button>
              </div>
              <div class="modal-body">

        <form @submit.prevent="login">
          <div class="form-group has-feedback">
            <input
              type="text"
              class="form-control"
              placeholder="Username"
              v-model="formData.name"
            />
            <span
              class="glyphicon glyphicon-envelope form-control-feedback"
            ></span>
          </div>
          <div class="form-group has-feedback">
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              v-model="formData.email"
            />
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
          <div class="form-group has-feedback">
            <select
              class="form-control"
              v-model="formData.district"
            >
            <option value="">Select Region</option>
          <option :value="i.id" v-for="i in districts" :key="i.id" >{{ i.name }}</option>
          </select>
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
          <div class="form-group has-feedback" v-if="formData.district">
            <select
              class="form-control"
              v-model="formData.constituency"
            >
            <option value="">Select Constituency</option>
          <option :value="i.id" v-for="i in districts.find(e=>e.id==formData.district).constituencies" :key="i.id" >{{ i.name }}</option>
          </select>
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
          <div class="form-group has-feedback">
            <select
              class="form-control"
              v-model="formData.role"
            >
            <option value="">Select Role</option>
          <option :value="i.code" v-for="i in roles" :key="i.id" >{{ i.name }}</option>
          </select>
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
           <div class="form-group has-feedback">
            <input
              type="password"
              class="form-control"
              placeholder="password"
              v-model="formData.password"
            />
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
          <div class="form-group has-feedback">
            <input
              type="password"
              class="form-control"
              placeholder="Confirm Password"
              v-model="formData.confirmpassword"
            />
            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
          </div>
          <div class="row">
            <div class="col-xs-8">
            </div>
          </div>
           <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Save</button>
              </div>
        </form>    
        </div>
            </div>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div> 
              <div class="row">
              <div class="col-md-12">
                            <div class="card shadow mb-4">
                        <div class="card-header py-3">
                            <h6 class="m-0 font-weight-bold text-primary">Users</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>UserName</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Region</th>
                                            <th>Constituency</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       <tr v-for="(i,index) in users" :key="index">
                                           <td>{{i.id}}</td>
                                         <td>{{i.name}}</td>
                                         <td>{{i.email}}</td>
                                         <td>{{i.role}}</td>
                                         <td>{{i.district ? i.district.name : ''}}</td>
                                         <td>{{ i.constituency ? i.constituency.name : '' }}</td>
                                         <td>
                                             <a href="#" class="btn btn-primary"  @click="editUser(i,index)">Edit</a>
                                             <a href="#" class="btn btn-danger"  @click="deleteUser(i.id,index)">Delete</a>
                                             
                                         </td>
                                     </tr>
                                    </tbody>
                                </table>
                            </div>
                            <Paginate :pagination="pgnate" @paginate="fetchUsers()" :offset="4"/> 
                               <div class="box">
                                    <div class="col-md-12">
              <!-- /.widget-user -->
            </div>
          </div>
                        </div>
                    </div>
                </div>
        </div>
    </Content>
</template>

<script>
import Content from './components/Content.vue';
import Paginate from './components/Paginate.vue'
import '../../assets/vendor/fontawesome-free/css/all.css'
import {Modal} from 'bootstrap'
    export default {
        components: {
            Content,
            Paginate
        },
         data(){
            return {
                modalref:null,
                    formData:{
                      id:'',
                      action:'add',
                    name:'',
                    email:'',
                    district:'',
                    constituency:'',
                    confirmpassword:'',
                    role:'',
                    password:''
                },
               users:[],
               districts:[],
               roles:[],
               userDetails:{},
                 ref:'',
                 pgnate: {
                    total: 0,
                    per_page: 2,
                    from: 1,
                    to: 0,
                    current_page: 1
                },
                offset: 4,
            }
        },
        methods:{
            login(){
              if(this.formData.name===''){
                alert("Please enter name")
                return
              }
              if(this.formData.email===''){
                alert("Please enter email")
                return
              }
              if(this.formData.district===''){
                alert("Please enter district")
                return
              }
              if(this.formData.constituency===''){
                alert("Please enter constituency")
                return
              }
              if(this.formData.role===''){
                alert("Please enter role")
                return
              }
              if(this.formData.password===''){
                alert("Please enter password")
                return
              }
         this.axios.post(this.appenv.baseurl+'/add-new-user',this.formData).then(()=>{ 
                     
                        this.formData.id=''
                        this.formData.name = '',
                        this.formData.email = '',
                        this.formData.password = ''
                        this.formData.confirmpassword = ''
                        this.formData.role = ''
                        this.formData.action = 'add'
                        this.modalref.hide()
                        this.fetchUsers()
                     
                })
            },
            getDistricts(){
              this.axios.get(this.appenv.baseurl+'/get-districts').then((response) => {
                if(response.data){
                    this.districts = response.data.districts
                }
                }).catch(()=>{})
            },
            editUser(item){
              console.log(item)
              this.formData.id=item.id
          this.formData.name = item.name
          this.formData.email = item.email
          this.formData.role = item.role
          this.formData.district = item.district ? item.district.id : ''
          this.formData.constituency = item.constituency ? item.constituency.id : ''
            this.formData['action'] = 'edit'
            this.modalref.show()
            },
            getRoles(){
              this.axios.get(this.appenv.baseurl+'/get-roles').then((response) => {
                if(response.data){
                    this.roles = response.data.roles
                }
                }).catch(()=>{})
            },
             fetchUsers(){
              this.axios.get(this.appenv.baseurl+'/get-users'+'?page='+this.pgnate.current_page).then((response) => {
                if(response.data){
                    this.users = response.data.users.data
                     this.pgnate = response.data.users;
                }
                }).catch(()=>{
                    this.$alert("Ooops.. Loading Data failed. Please try again",'Alert','error');
                })
          }, 
          deleteUser(item, index){
             this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result)=>{
        if(result.isConfirmed){
          this.axios.post(this.appenv.baseurl+'/delete-user', {id:item}).then((response)=>{
                  this.users.splice(index, 1)
                  console.log(response)
          })
        }
      })
              
          }   
        },
        mounted(){
          this.modalref = new Modal(document.getElementById("modal-default"), {});
            this.fetchUsers()
            this.getDistricts()
            this.getRoles()
        }
    }
</script>

<style lang="scss" scoped>

</style>
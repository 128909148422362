import axios from 'axios'
import store from "@/store/store.js"
let ax =  axios.create({})
ax.interceptors.request.use(function (config) {
    if(config.url.indexOf('?') !== -1){
        config.url = config.url +'&token='+ (store.state.AppActiveUser.token || '')
    }else{
        config.url = config.url +'?token='+ (store.state.AppActiveUser.token || '')
    }
  return config;
}, function (error) {
  return Promise.reject(error);
}); 
export default ax
